<template>
  <div>
    <div class="tw-p-4">
      <UiTitle
        small
        class="tw-my-3"
        :title="$t('leads.individual.changeLocation.informations.title', { ...lexicon })"
      ></UiTitle>
      <template>
        <v-autocomplete
          class="tw-mt-6"
          v-model="newLocation"
          outlined
          no-filter
          item-text="label"
          item-value="id"
          :hide-no-data="!searchValue || searchValue.length <= 2"
          :loading="isLocationLoading"
          :items="lpeLocations"
          :placeholder="$t('leads.individual.changeLocation.autocomplete.placeholder', { ...lexicon })"
          @update:search-input="onInput"
          @change="changeInput"
        >
          <template v-slot:item="{ item }">
            <div>
              <div>{{ item.label }}</div>
              <div v-if="item.address" class="tw-text-xs tw-text-gray-400">{{ item.address }}</div>
            </div>
          </template>
        </v-autocomplete>
      </template>

      <div class="tw-flex tw-items-center" v-if="newLocation">
        <UiTitle small :title="$t('leads.individual.changeLocation.forward.title')"></UiTitle>
        <v-spacer></v-spacer>
        <v-switch v-model="hasForward"></v-switch>
      </div>

      <v-divider class="ui-separator"></v-divider>
    </div>
    <div v-if="newLocation && hasForward">
      <PlatformLeadsForward
        ref="forward"
        :lead="lead"
        :isSaving="isSaving"
        :administrators="administrators"
        :mustBeEmptied="mustBeEmptied"
        :triggerAction="triggerAction"
        @setIsOwnerInvalid="setIsOwnerInvalid"
        @forward="forward"
      />
    </div>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { debounce } from '@/utils/utilities.util'
import PlatformLeadsForward from '@/components/Leads/Forward.vue'
import UiTitle from '@/components/UI/Title.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'PlatformLeadsChangeLocation',
  components: {
    UiTitle,
    PlatformLeadsForward,
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    lpeLocations: {
      type: Array,
      required: true,
    },
    mustBeEmptied: {
      type: Boolean,
      required: true,
    },
    triggerAction: {
      type: Boolean,
      required: true,
    },
    lexicon: {
      type: Object,
    },
  },
  data: () => ({
    newLocation: null,
    isLocationLoading: false,
    notify: true,
    searchValue: '',
    hasForward: true,
    clientId: null,
    icons: {
      mdiClose,
    },
  }),
  watch: {
    searchValue: {
      handler() {
        if (this.searchValue && this.searchValue.length > 2) {
          this.isLocationLoading = true
          this.$emit('searchLocations', this.searchValue)
        }
      },
      deep: true,
    },
    lpeLocations: {
      handler() {
        this.isLocationLoading = false
      },
      deep: true,
    },
    mustBeEmptied: {
      handler(newValue) {
        if (newValue) {
          this.cancelLocationChange()
          this.$emit('closeDrawer')
        }
      },
      deep: true,
    },
    triggerAction: {
      handler() {
        if (this.triggerAction === true) {
          this.changeLocation()
        }
      },
      deep: true,
    },
  },

  created() {
    this.clientId = this.$route.params.clientId
    this.cancelLocationChange()
  },
  computed: {
    ...mapState({
      administrators: state => state.leads.administrators,
    }),
  },
  methods: {
    ...mapActions({
      getAdministrators: 'leads/getAdministrators',
    }),
    forward(payload) {
      this.$emit('forward', { url: this.lead.actions.forward, notify: this.notify, email: payload.email })
    },
    changeLocation() {
      this.$emit('changeLocation', { url: this.lead.actions.changeLocation, locationId: this.newLocation })
    },
    cancelLocationChange() {
      this.newLocation = null
      this.notify = true
    },
    getLocationByLabel(label) {
      return this.lpeLocations.find(location => location.label == label)
    },

    onInput: debounce(async function (value) {
      if (value === null || value === this.getLocationByLabel(value)?.label) return
      this.searchValue = value
    }, 300),

    setIsOwnerInvalid(value) {
      this.$emit('setIsOwnerInvalid', value)
    },

    async searchAdministrators(query) {
      await this.getAdministrators(query)
    },

    async changeInput() {
      this.searchValue = null
      if (this.newLocation !== null) {
        await this.searchAdministrators(this.newLocation)
        return this.$emit('setHasNewValueToSave', true)
      }
      return this.$emit('setHasNewValueToSave', false)
    },
  },
}
</script>
