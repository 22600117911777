<template>
  <UiDrawer class="individual-leads" :open="open" @toggle="toggle" :sentKey="currentLead.id">
    <template v-slot:prepend>
      <v-toolbar
        :color="$vuetify.theme.dark ? 'hsl(222, 36%, 10%)' : '#F5F7FA'"
        class="tw-w-full"
        height="56"
        :elevation="2"
      >
        <div class="tw-mr-4">
          <v-btn
            class="tw-mr-2"
            icon
            outlined
            small
            :disabled="!hasPrevButton || currentLeadLoading"
            @click="getPreviousLead"
          >
            <v-icon>{{ icons.mdiMenuUp }} </v-icon></v-btn
          >
          <v-btn icon outlined small :disabled="!hasNextButton || currentLeadLoading" @click="getNextLead">
            <v-icon>{{ icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </div>
        <div v-if="currentLead.price" class="tw-text-bold tw-text-lg">
          {{ $t('leads.individual.header.price') }}: <span class="tw-font-semibold">{{ leadPriceDisplay }}</span>
        </div>
        <div v-else class="tw-text-bold tw-text-lg">
          {{ $t('leads.individual.header.title') }}
        </div>
        <v-spacer />
        <div>
          <span v-if="currentLead.status">
            <LeadsIndividualFieldsChip :field="{ value: currentLead.status }" />
          </span>
          <span v-if="currentLead.actions" class="tw-ml-2">
            <LeadsListRowsActions :field="currentLead" @executeAction="executeAction" />
          </span>
          <span class="tw-ml-2">
            <v-btn icon outlined small @click="toggle(false)">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </span>
        </div>
      </v-toolbar>
      <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>
    </template>
    <template v-slot:content
      ><div>
        <v-tabs v-model="individualTab" class="individual-leads__tabs" fixed-tabs>
          <v-tab v-for="tabItem in individualTabsItems" :key="tabItem.tab" :disabled="editMode">
            {{ $t(`leads.individual.tabs.${tabItem.value}`) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="individualTab" class="individual-leads__content">
          <v-tab-item v-for="tabItem in individualTabsItems" :key="tabItem.tab">
            <div class="tw-p-4" v-if="tabItem.value === 'data'">
              <LeadsIndividualLeadValues
                :lead="currentLead"
                :editMode="editMode"
                :availableTags="availableTags"
                :isProdutForm="currentForm.settings.product"
                :key="editMode"
                :modifiedLead="modifiedLead"
                :lexicon="currentFormLexicon"
                @updateValue="updateLeadValue($event)"
              />

              <div v-if="!isEmpty(currentLead.identity) && !editMode" class="tw-mt-10 tw-mb-5">
                <LeadsIndividualIdentity
                  :identity="currentLead.identity"
                  :lexicon="currentFormLexicon"
                ></LeadsIndividualIdentity>
              </div>

              <div v-if="hasFormValues">
                <UiTitle
                  class="tw-text-bold tw-text-lg tw-mt-10 tw-mb-5"
                  :title="capitalize(currentFormLexicon.fieldValues)"
                />

                <!-- Lead fields values -->
                <div v-if="editMode">
                  <div v-for="(fieldValue, index) in currentFormSubmission.fieldsValues" :key="index">
                    <div class="tw-flex tw-flex-col tw-mb-2">
                      <div class="row-title">{{ fieldValue.name }}</div>
                      <component
                        :is="getComponentFieldName(fieldValue)"
                        :key="`${index}edit`"
                        :currentForm="currentForm"
                        :field="fieldValue"
                        :options="getComponentOptions(fieldValue)"
                        :possibleValues="getFieldPossibleValues(fieldValue)"
                        :editMode="true"
                        :locale="$i18n.locale"
                        @updateField="updateFieldValue($event)"
                      />
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-for="(fieldValue, index) in currentLead.fieldsValues" :key="index">
                    <div class="tw-flex tw-flex-col tw-mb-2">
                      <div class="row-title">{{ fieldValue.name }}</div>
                      <UiCountryLabel v-if="fieldValue.type === 'country'" :label="fieldValue.value" />
                      <component
                        v-else
                        :is="getComponentFieldName(fieldValue)"
                        :key="`${index}read`"
                        :currentForm="currentForm"
                        :options="getComponentOptions(fieldValue)"
                        :field="fieldValue"
                        :editMode="false"
                        :locale="$i18n.locale"
                      />
                      <v-divider class="ui-separator"></v-divider>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="currentLead.additionalData && currentLead.additionalData.length > 0">
                <LeadsIndividualAdditionalData
                  :additionalData="currentLead.additionalData"
                  :lexicon="currentFormLexicon"
                ></LeadsIndividualAdditionalData>
              </div>
            </div>
            <div class="tw-p-4" v-if="tabItem.value === 'metadata'">
              <span v-if="isEmpty(currentLead.metadata)" class="empty-field">{{
                $t('leads.individual.metadata.noData')
              }}</span>
              <div class="tw-mb-2" v-for="(currentMetadata, key) in currentLead.metadata" :key="key">
                <div class="row-title">{{ $t(`leads.individual.metadata.${key}`) }}</div>
                <div class="row-value">{{ currentMetadata }}</div>
                <v-divider class="ui-separator"></v-divider>
              </div>
            </div>
            <div class="tw-p-4" v-if="tabItem.value === 'history'">
              <LeadsIndividualHistory :history="currentLead.history"></LeadsIndividualHistory>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <UiActions v-if="individualTab === INDIVIDUAL_TAB.DATA" class="individual-leads__actions" large centered>
        <v-btn v-if="editMode" @click="cancelEditMode()" text color="primary">{{ $t('button.cancel') }}</v-btn>
        <v-btn
          color="primary"
          type="submit"
          :loading="isSaving"
          :disabled="isSaving || currentlyEditingLead"
          @click="editMode ? saveModifiedLead() : enableEditMode()"
        >
          <v-icon v-if="!editMode">{{ icons.mdiPlaylistEdit }}</v-icon>
          <v-icon v-else>{{ icons.mdiContentSaveCheckOutline }}</v-icon>
          <span v-if="!editMode">{{ $t('button.edit') }}</span>
          <span v-else>{{ $t('button.save') }}</span>
        </v-btn>
      </UiActions>
    </template>
  </UiDrawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LeadsIndividualFieldsText from '@/components/Leads/Individual/Fields/Text'
import LeadsIndividualFieldsTextarea from '@/components/Leads/Individual/Fields/Textarea'
import LeadsIndividualFieldsCheckbox from '@/components/Leads/Individual/Fields/Checkbox'
import LeadsIndividualFieldsChip from '@/components/Leads/Individual/Fields/Chip'
import LeadsIndividualFieldsChipList from '@/components/Leads/Individual/Fields/ChipList'
import LeadsIndividualFieldsDate from '@/components/Leads/Individual/Fields/Date'
import LeadsIndividualFieldsFile from '@/components/Leads/Individual/Fields/File'
import LeadsIndividualFieldsRadio from '@/components/Leads/Individual/Fields/Radio'
import LeadsIndividualFieldsMultiselect from '@/components/Leads/Individual/Fields/Multiselect'
import LeadsIndividualFieldsPrice from '@/components/Leads/Individual/Fields/Price'
import LeadsIndividualFieldsSkus from '@/components/Leads/Individual/Fields/Skus'
import LeadsIndividualFieldsCountry from '@/components/Leads/Individual/Fields/Country'
import LeadsIndividualHistory from '@/components/Leads/Individual/History'
import LeadsIndividualIdentity from '@/components/Leads/Individual/Identity'
import LeadsIndividualLeadValues from '@/components/Leads/Individual/LeadValues'
import LeadsIndividualAdditionalData from '@/components/Leads/Individual/AdditionalData'
import LeadsListRowsActions from '@/components/Leads/List/Rows/Actions'

import UiCountryLabel from '@/components/UI/CountryLabel.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiActions from '@/components/UI/Actions.vue'
import UiDrawer from '@/components/UI/Drawer/Drawer.vue'
import { mdiMenuUp, mdiMenuDown, mdiClose, mdiContentSaveCheckOutline, mdiPlaylistEdit } from '@mdi/js'
import { isEmpty, formattedPrice } from '@/utils/helper.util'
import { capitalize } from '@/utils/formatter.util'
import { countries } from '@/config/countries.config'

export default {
  name: 'PlatformLeadsIndividual',
  components: {
    LeadsListRowsActions,
    LeadsIndividualFieldsSkus,
    LeadsIndividualFieldsText,
    LeadsIndividualFieldsTextarea,
    LeadsIndividualFieldsCheckbox,
    LeadsIndividualFieldsRadio,
    LeadsIndividualFieldsChip,
    LeadsIndividualFieldsChipList,
    LeadsIndividualFieldsDate,
    LeadsIndividualFieldsFile,
    LeadsIndividualFieldsMultiselect,
    LeadsIndividualFieldsPrice,
    LeadsIndividualHistory,
    LeadsIndividualAdditionalData,
    LeadsIndividualIdentity,
    LeadsIndividualLeadValues,
    UiCountryLabel,
    LeadsIndividualFieldsCountry,
    UiActions,
    UiDrawer,
    UiTitle,
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLead: {
      type: Object,
      required: true,
    },
    currentForm: {
      type: Object,
      required: true,
    },
    currentLeadLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasNextButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasPrevButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    availableTags: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    INDIVIDUAL_TAB: {
      DATA: 0,
      METADATA: 1,
      HISTORY: 2,
    },
    componentItemsList: [],
    individualTab: null,
    icons: {
      mdiMenuUp,
      mdiMenuDown,
      mdiClose,
      mdiContentSaveCheckOutline,
      mdiPlaylistEdit,
    },
    isEmpty,
    isSaving: false,
    loading: false,
    currentlyEditingLead: false,
    editMode: false,
    modifiedLead: {},
  }),
  computed: {
    ...mapState({
      currentFormSubmission: state => state.leads.currentFormSubmission,
      currentFormLexicon: state => state.leads.currentFormLexicon,
    }),
    individualTabsItems() {
      return [
        { tab: this.INDIVIDUAL_TAB.DATA, value: 'data' },
        { tab: this.INDIVIDUAL_TAB.HISTORY, value: 'history' },
        { tab: this.INDIVIDUAL_TAB.METADATA, value: 'metadata' },
      ]
    },
    getLeadActionsItem() {
      const actionsItem = {
        actions: this.currentLead.actions,
        links: this.currentLead.links,
      }

      return actionsItem
    },
    hasFormValues() {
      return (
        (this.editMode === false && this.currentLead.fieldsValues.length > 0) ||
        (this.editMode === true && this.currentFormSubmission.fieldsValues.length > 0)
      )
    },
    leadPriceDisplay() {
      return formattedPrice({
        number: this.currentLead.price.value,
        currency: this.currentLead.price.currency,
      })
    },
    isLoading() {
      return this.currentLeadLoading || this.loading
    },
    countriesList() {
      return countries[this.$i18n.locale]
    },
  },
  methods: {
    ...mapActions({
      setCurrentFormSubmission: 'leads/setCurrentFormSubmission',
    }),
    cancelEditMode() {
      this.editMode = false
      this.modifiedLead = {}
    },
    toggle(bool) {
      this.$emit('toggle', bool)
    },
    getPreviousLead() {
      this.$emit('changeCurrentLead', 'previous')
    },
    getNextLead() {
      this.$emit('changeCurrentLead', 'next')
    },
    executeAction({ url, status, id }) {
      this.$emit('executeAction', { url, status, id })
    },
    updateFieldValue(updatedField) {
      this.modifiedLead.fieldsValues = { ...this.modifiedLead.fieldsValues, ...updatedField }
    },
    updateLeadValue(updatedValue) {
      this.modifiedLead = { ...this.modifiedLead, ...updatedValue }
    },
    getComponentFieldName(item) {
      let suffix = ''

      switch (item.type) {
        case 'date':
        case 'datepicker':
          suffix = 'Date'
          break
        case 'checkbox':
          suffix = 'Checkbox'
          break
        case 'select':
          suffix = 'Multiselect'
          break
        case 'country':
          suffix = 'Country'
          break
        case 'file':
          suffix = 'File'
          break
        case 'ChipList':
          suffix = 'ChipList'
          break
        case 'radio':
          suffix = 'Radio'
          break
        case 'price':
          suffix = 'Price'
          break
        case 'skus':
          suffix = 'Skus'
          break
        case 'textarea':
          suffix = 'Textarea'
          break
        default:
          suffix = 'Text'
          break
      }

      return `LeadsIndividualFields${suffix}`
    },
    getComponentOptions(field) {
      const options = {}
      if (field.type === 'datepicker') {
        options.format = 'L'
      }
      if (['text', 'email', 'url', 'number'].includes(field.type)) {
        options.inputType = field.type
      }
      if (['rating'].includes(field.type)) {
        options.inputType = 'number'
      }
      if (field.multiple !== undefined) {
        options.isMultiple = field.multiple
      }
      return options
    },
    getFieldPossibleValues(field) {
      return field.options || []
    },
    saveModifiedLead() {
      const mergedLead = { ...this.currentLead, ...this.modifiedLead }
      this.$emit('saveModifiedLead', mergedLead)
      this.editMode = false
    },
    async enableEditMode() {
      this.loading = true

      await this.setCurrentFormSubmission({
        formId: this.currentForm ? this.currentForm.id : '',
        leadId: this.currentLead.id,
      })

      this.loading = false
      this.editMode = true
    },
    formattedPrice,
    capitalize,
  },
}
</script>

<style lang="scss" scoped>
.ui-separator {
  @apply tw-mt-2 tw-border-gray-200 dark:tw-border-opacity-10;
}

.row-title {
  @apply tw-text-sm;
  opacity: 0.5;
}
.row-value {
  overflow: hidden;
  text-overflow: ellipsis;
}

.individual-leads {
  &__tabs {
    position: relative;
    z-index: 1;
    border-bottom: 1px solid rgba(229, 231, 235, var(--tw-border-opacity));
  }

  &__content {
    padding-top: 0;
    padding-bottom: 73px;
  }

  &__actions {
    width: 100%;
    left: inherit;
  }
}
</style>
