<template>
  <div>
    <div>
      <div class="tw-p-4">
        <div class="tw-mb-3">
          <UiTitle small class="tw-my-3" :title="$t('leads.individual.forward.informations.title')"></UiTitle>
          <UiSubtitle :subtitle="$t('leads.individual.forward.informations.subtitle')"></UiSubtitle>
        </div>
        <v-combobox
          v-model="newOwner"
          :label="$t('leads.individual.forward.combo.placeholder')"
          hide-details="auto"
          outlined
          chips
          clearable
          :items="availableNewOwners"
          :error-messages="invalidEmailError"
          :return-object="false"
          @change="changeInput"
        >
        </v-combobox>
      </div>
      <v-divider class="ui-separator"></v-divider>
      <div class="tw-p-4">
        <div class="tw-mb-3">
          <div class="tw-flex tw-items-center">
            <UiTitle small :title="$t('leads.individual.forward.notifications.title')"></UiTitle>
            <v-spacer></v-spacer>
            <v-switch v-model="notify"></v-switch>
          </div>

          <UiSubtitle :subtitle="$t('leads.individual.forward.notifications.subtitle')"></UiSubtitle>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import { email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  name: 'PlatformLeadsForward',
  components: {
    UiTitle,
    UiSubtitle,
  },
  mixins: [validationMixin],
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    lead: {
      type: Object,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    mustBeEmptied: {
      type: Boolean,
      required: true,
    },
    administrators: {
      type: Array,
      required: false,
    },
    triggerAction: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    newOwner: null,
    notify: true,
    icons: {
      mdiClose,
    },
  }),
  watch: {
    mustBeEmptied: {
      handler(newValue) {
        if (newValue) {
          this.cancelForward()
          this.$emit('closeDrawer')
        }
      },
      deep: true,
    },
    triggerAction: {
      handler() {
        if (this.triggerAction === true) {
          this.forward()
        }
      },
      deep: true,
    },
  },
  created() {
    this.newOwner = null
    this.$emit('setIsOwnerInvalid', false)
  },
  computed: {
    availableNewOwners() {
      if (this.administrators?.length > 0) {
        return this.administrators.map(admin => ({
          text: `${admin.email} (${this.$t(
            `forms.individual.notifications.internal.email.location.representative.${admin.role}`
          )})`,
          value: admin.email,
        }))
      }
      return this.lead.newLeadSubscribers.filter(subscriber => subscriber !== this.lead.owner)
    },
    invalidEmailError() {
      const errors = []
      if (!this.$v.newOwner.$dirty || !this.newOwner) return errors
      !this.$v.newOwner.email && errors.push(this.$t('error.email'))
      return errors
    },
    isOwnerInvalid() {
      return !this.$v.newOwner.email || this.$v.newOwner.email.$error
    },
  },
  methods: {
    forward() {
      this.$emit('forward', { url: this.lead.actions.forward, notify: this.notify, email: this.newOwner })
    },
    cancelForward() {
      this.newOwner = null
      this.notify = true
    },
    changeInput() {
      this.$v.$touch()
      this.$emit('setIsOwnerInvalid', this.isOwnerInvalid)
      this.$emit('setHasNewValueToSave', this.newOwner !== null)
    },
  },
  validations() {
    return {
      newOwner: { email },
    }
  },
}
</script>

<style lang="scss" scoped>
.forward-lead {
  &__button-container {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 15px;
    bottom: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    text-align: center;
    width: 100%;
  }
}
</style>
