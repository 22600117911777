export const formatErrorMessage = error => {
  let formattedErrorsDetails = ''
  for (const detail in error.data.errors) {
    formattedErrorsDetails += `${error.data.errors[detail].slug} : ${error.data.errors[detail].rules.join(', ')} <br/>`
  }
  const formattedErrorMessage = `${error.data.message} :<br/>
  ${formattedErrorsDetails}`

  return formattedErrorMessage
}
