<template>
  <v-navigation-drawer
    class="individual-leads"
    :value="open"
    absolute
    temporary
    disable-route-watcher
    right
    :width="$vuetify.breakpoint.xsOnly ? '100%' : 600"
    @input="toggle"
  >
    <slot name="prepend"></slot>
    <slot name="content"></slot>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'UiDrawer',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    toggle(bool) {
      this.$emit('toggle', bool)
    },
  },
}
</script>
