<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div
      v-else
      class="leads-list"
      tabindex="0"
      v-on:keyup.esc="closeIndividual"
      v-on:keyup.down="changeCurrentLead('next')"
      v-on:keyup.up="changeCurrentLead('previous')"
    >
      <div class="leads-list__content" :class="{ 'leads-list__content--expanded': filterOpen }">
        <div v-if="formsList.total !== 0 && currentForm">
          <UiBanner>
            <template v-slot:content>
              <div class="leads-list__content__platform-leads">
                <LeadsTopBar :formsList="formsList" :currentForm="currentForm" @changeForm="changeForm">
                  <template v-slot:label>
                    <div
                      class="tw-flex tw-items-center tw-font-medium tw-text-xl tw-leading-6 tw-flex-grow-1 tw-mr-1"
                      v-dompurify-html="
                        $tc('leads.list.title.label', currentLeadsList.total, { count: currentLeadsList.total })
                      "
                    ></div>
                  </template>
                  <template v-slot:actions>
                    <v-badge class="tw-ml-4" overlap color="primary" :content="filterCount" :value="filterCount">
                      <v-btn
                        class="tw-flex tw-ml-2 tw-mt-2 sm:tw-mt-0 tw-leading-2 tw-flex-grow-1"
                        outlined
                        color="primary"
                        rounded
                        :disabled="!hasAvailableFilters"
                        @click="toggleFilters(true)"
                      >
                        <v-icon left>{{ icons.mdiFilterOutline }}</v-icon>
                        <span>{{ $t('button.filter') }}</span>
                      </v-btn>
                    </v-badge>

                    <v-badge
                      class="tw-ml-4"
                      overlap
                      color="primary"
                      :content="currentLeadsList.total"
                      :value="currentLeadsList.total"
                    >
                      <v-btn
                        class="tw-flex tw-ml-2 tw-mt-2 sm:tw-mt-0 tw-leading-2 tw-flex-grow-1"
                        color="primary"
                        rounded
                        :disabled="currentLeadsList.total === 0"
                        @click="exportLeads()"
                      >
                        <v-progress-circular
                          class="tw-mr-2"
                          size="20"
                          width="3"
                          v-if="exportWorking"
                          indeterminate
                        ></v-progress-circular>
                        <v-icon v-else left>{{ icons.mdiExport }}</v-icon>
                        <span>{{ $t('button.export') }}</span>
                      </v-btn>
                    </v-badge></template
                  >
                </LeadsTopBar>
              </div>
            </template>
          </UiBanner>
        </div>
        <div v-else-if="formsList.total === 0"><UiBanner :title="$t('leads.list.title.noForm')"> </UiBanner></div>

        <div class="leads-list__content__main">
          <uiContainer no-padding>
            <div class="leads-list__content__main__toolbar" :class="{ 'theme--dark': $vuetify.theme.dark }">
              <v-text-field
                :value="search"
                solo
                flat
                outlined
                clearable
                placeholder="Search"
                dense
                :prepend-inner-icon="icons.mdiMagnify"
                hide-details
                @input="searchLeads"
              />
            </div>

            <div class="leads-list__content__main__table-container leads-list__content__main__table-container--search">
              <v-data-table
                :headers="currentLeadsList.headers"
                :items="currentLeadsList.rows"
                :options="options"
                :loading="refreshing"
                :footer-props="{
                  itemsPerPageOptions: [25, 50, 100],
                }"
                :item-class="currentLead ? getSelectedClass : ''"
                :server-items-length="currentLeadsList.total"
                class="tw-rounded-none tw-h-full tw-flex tw-flex-col leads-list__content__main__table-container__table"
                disable-sort
                fixed-header
                selectable-key
                @update:options="changeOptions"
                @click:row="openIndividual($event.id)"
              >
                <template
                  v-for="(header, index) in currentLeadsList.headers"
                  v-slot:[`item.${header.value}`]="{ item }"
                >
                  <span :key="index">
                    <component
                      :is="componentItemName(header)"
                      :field="item"
                      :fieldKey="header.value"
                      :options="header.options"
                      :search="search"
                      :locale="currentUser.locale"
                      @executeAction="executeAction"
                    />
                  </span>
                </template>
              </v-data-table>
            </div>
          </uiContainer>
        </div>
      </div>

      <PlatformLeadsListFilters
        ref="filters"
        :open="filterOpen"
        :genericFilters="genericFilters"
        :fieldFilters="fieldFilters"
        :currentFilters="currentFilters"
        :currentUser="currentUser"
        :lexicon="currentFormLexicon"
        @toggle="toggleFilters"
        @filter="filterView"
      />

      <PlatformLeadsIndividual
        v-if="individualOpen && currentLead !== null"
        ref="individual"
        :key="`${currentLeadIndexInPage}`"
        :open="individualOpen && currentLead !== null"
        :currentLead="currentLead"
        :currentForm="currentForm"
        :hasNextButton="hasNextButton"
        :hasPrevButton="hasPrevButton"
        :currentLeadLoading="currentLeadLoading"
        :availableTags="availableTags"
        @toggle="toggleIndividual"
        @changeCurrentLead="changeCurrentLead"
        @executeAction="executeAction"
        @saveModifiedLead="saveModifiedLead"
      />

      <UiActionDrawer
        :open="actionDrawerOpened"
        :title="getDrawerTitle"
        :hasActions="hasActions"
        :isOwnerInvalid="isOwnerInvalid"
        @toggle="toggleActionDrawer"
        @cancelAction="cancelAction"
        @drawerAction="drawerAction"
      >
        <template v-slot:content>
          <PlatformLeadsForward
            v-if="currentActionDrawer === FORWARD_OWNER_DRAWER && activatedLead"
            ref="forward"
            :lead="activatedLead"
            :isSaving="isForwardSaving"
            :mustBeEmptied="mustBeEmptied"
            :triggerAction="triggerAction"
            @setHasNewValueToSave="setHasNewValueToSave"
            @setIsOwnerInvalid="setIsOwnerInvalid"
            @closeDrawer="closeDrawer"
            @forward="forward"
          />
          <PlatformLeadsChangeLocation
            v-if="currentActionDrawer === CHANGE_LOCATION_DRAWER && activatedLead"
            ref="changeLocation"
            :lead="activatedLead"
            :lpeLocations="lpeLocations"
            :isSaving="isChangeLocationSaving"
            :mustBeEmptied="mustBeEmptied"
            :triggerAction="triggerAction"
            :lexicon="currentFormLexicon"
            @setHasNewValueToSave="setHasNewValueToSave"
            @setIsOwnerInvalid="setIsOwnerInvalid"
            @searchLocations="searchLocations"
            @closeDrawer="closeDrawer"
            @forward="forward"
            @changeLocation="changeLocation"
          />
        </template>
      </UiActionDrawer>
      <UiConfirmOverlay
        :active="displayDeleteLeadConfirmOverlay"
        :title="$t('leads.overlays.delete.title')"
        :body="$t('leads.overlays.delete.body')"
        :loading="confirmDeleteLeadOverlayisLoading"
        @close="cancelLeadDeletion"
        @confirm="deleteLead"
      />
    </div>
  </v-fade-transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiFilterOutline, mdiMagnify, mdiExport, mdiHelpCircleOutline } from '@mdi/js'
import { debounce } from '@/utils/utilities.util'
import { formatErrorMessage } from '@/utils/errors.util'

import LeadsTopBar from '@/components/Leads/LeadsTopBar'
import UiBanner from '@/components/UI/Banner.vue'
import UiConfirmOverlay from '@/components/UI/ConfirmOverlay.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiActionDrawer from '@/components/UI/Drawer/ActionDrawer.vue'
import LeadsListRowsText from '@/components/Leads/List/Rows/Text'
import LeadsListRowsCheckbox from '@/components/Leads/List/Rows/Checkbox'
import LeadsListRowsRadio from '@/components/Leads/List/Rows/Radio'
import LeadsListRowsChip from '@/components/Leads/List/Rows/Chip'
import LeadsListRowsChipList from '@/components/Leads/List/Rows/ChipList'
import LeadsListRowsDate from '@/components/Leads/List/Rows/Date'
import LeadsListRowsFile from '@/components/Leads/List/Rows/File'
import LeadsListRowsLocation from '@/components/Leads/List/Rows/Location'
import LeadsListRowsIdentity from '@/components/Leads/List/Rows/Identity'
import LeadsListRowsActions from '@/components/Leads/List/Rows/Actions'
import LeadsListRowsMultiselect from '@/components/Leads/List/Rows/Multiselect'
import LeadsListRowsPrice from '@/components/Leads/List/Rows/Price'
import LeadsListRowsSkus from '@/components/Leads/List/Rows/Skus'
import LeadsListRowsCountry from '@/components/Leads/List/Rows/Country'
import PlatformLeadsListFilters from '@/components/Leads/List/Filters.vue'
import PlatformLeadsIndividual from '@/components/Leads/Individual.vue'
import PlatformLeadsForward from '@/components/Leads/Forward.vue'
import PlatformLeadsChangeLocation from '@/components/Leads/ChangeLocation.vue'
import { isEmpty } from '@/utils/helper.util'

export default {
  name: 'PlatformLeads',
  components: {
    UiBanner,
    UiContainer,
    UiActionDrawer,
    LeadsListRowsText,
    LeadsListRowsCheckbox,
    LeadsListRowsRadio,
    LeadsListRowsChip,
    LeadsListRowsChipList,
    LeadsListRowsDate,
    LeadsListRowsFile,
    LeadsListRowsIdentity,
    LeadsListRowsMultiselect,
    LeadsListRowsCountry,
    PlatformLeadsListFilters,
    PlatformLeadsIndividual,
    PlatformLeadsForward,
    LeadsListRowsLocation,
    PlatformLeadsChangeLocation,
    LeadsListRowsActions,
    LeadsListRowsPrice,
    LeadsListRowsSkus,
    LeadsTopBar,
    UiConfirmOverlay,
  },
  data: () => ({
    refreshing: false,
    compoOptions: {},
    options: {},
    filterOpen: false,
    individualOpen: false,
    forwardOpened: false,
    changeLocationOpened: false,
    exportWorking: false,
    currentLeadLoading: false,
    selectedLeadId: null,
    currentLeadIndexInPage: null,
    currentLeadsListLength: null,
    hasNextButton: true,
    hasPrevButton: true,
    hasActions: false,
    isOwnerInvalid: false,
    activatedLead: null,
    isForwardSaving: false,
    isChangeLocationSaving: false,
    mustBeEmptied: false,
    triggerAction: false,
    displayDeleteLeadConfirmOverlay: false,
    confirmDeleteLeadOverlayisLoading: false,
    CHANGE_LOCATION_DRAWER: 'changeLocationDrawer',
    FORWARD_OWNER_DRAWER: 'forwardOwnerDrawer',
    icons: {
      mdiHelpCircleOutline,
      mdiFilterOutline,
      mdiMagnify,
      mdiExport,
    },
  }),
  async mounted() {
    this.setUpdating(true)
    const query = this.$route.query
    try {
      await this.getFormsList()

      isEmpty(query.formId)
        ? await this.setCurrentForm(this.formsList.rows[0].id)
        : await this.setCurrentForm(query.formId)

      const filters = this.buildFilterFromQuery(query)
      this.setCurrentFilters(filters)

      if (query.search) {
        this.setSearch(query.search)
      }
      this.options.page = parseInt(query.page) || 1
      this.options.itemsPerPage = 25
      await this.loadLeads()

      if (query.leadId) {
        this.selectedLeadId = query.leadId
      }
    } catch {
      this.setAlert({
        color: 'error',
        text: this.$t('leads.notification.error.noForm'),
      })
    }
    this.setUpdating(false)
  },

  watch: {
    options: {
      async handler(newOptions, oldOptions) {
        if (newOptions !== oldOptions && this.currentForm) {
          await this.loadLeads()

          if (
            this.forwardOpen &&
            this.individualOpen &&
            this.currentLeadIndexInPage !== null &&
            this.currentLeadsList.rows[this.currentLeadIndexInPage]
          ) {
            this.selectedLeadId = this.currentLeadsList.rows[this.currentLeadIndexInPage].id
          }

          if (oldOptions.page && newOptions.page !== 1) {
            this.$router.push({ query: { ...this.$route.query, page: newOptions.page } }).catch(err => {
              return err
            })
          }
          if (oldOptions.page !== 1 && newOptions.page === 1) {
            this.removePageFromUrl()
          }
        }
      },
      deep: true,
    },
    currentForm: {
      async handler(newForm, oldForm) {
        if (newForm !== oldForm && this.currentForm) {
          this.$router.push({ query: { ...this.$route.query, formId: this.currentForm.id } }).catch(err => {
            return err
          })
        }
      },
      deep: true,
    },
    currentFilters: {
      async handler(newFilters, oldFilters) {
        if (newFilters !== oldFilters && this.currentForm) {
          const filters = this.currentFilters.reduce((filterParams, filter) => {
            if (['status', 'locationId', 'from', 'to', 'tags'].includes(filter.slug)) {
              filterParams[filter.slug] = filter.value
            }

            if (filter.type === 'fieldValue') {
              filterParams.fields = {
                ...(filterParams.fields || {}),
                [filter.slug]: filter.value,
              }
            }

            return filterParams
          }, {})

          this.$router
            .push({ query: { ...filters, search: this.search, formId: this.currentForm.id, page: this.options.page } })
            .catch(err => err)
        }
      },
      deep: true,
    },
    search: {
      async handler(newSearch, oldSearch) {
        if (newSearch !== oldSearch && this.currentForm) {
          this.$router.push({ query: { ...this.$route.query, search: this.search } }).catch(err => err)
        }
      },
      deep: true,
    },
    selectedLeadId: {
      async handler(newSelectedLeadId, oldSelectedLeadId) {
        if (newSelectedLeadId && newSelectedLeadId !== oldSelectedLeadId && this.currentForm) {
          this.currentLeadLoading = true
          const newQueryParams = { ...this.$route.query, leadId: this.selectedLeadId }
          if (this.options.page > 1) {
            newQueryParams.page = this.options.page
          }
          this.$router.push({ query: newQueryParams }).catch(err => err)
          await this.openIndividual(this.selectedLeadId)
          this.currentLeadLoading = false
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      currentClient: state => state.backoffice.currentClient,
      formsList: state => state.leads.formsList,
      currentLeadsList: state => state.leads.currentLeadsList,
      currentFormEditLeadHeaders: state => state.leads.currentFormEditLeadHeaders,
      currentFilters: state => state.leads.currentFilters,
      currentLead: state => state.leads.currentLead,
      genericFilters: state => state.leads.genericFilters,
      fieldFilters: state => state.leads.fieldFilters,
      search: state => state.leads.search,
      currentForm: state => state.leads.currentForm,
      currentUser: state => state.backoffice.currentUser,
      actionDrawerOpened: state => state.leads.actionDrawerOpened,
      currentActionDrawer: state => state.leads.currentActionDrawer,
      lpeLocations: state => state.leads.lpeLocations,
      currentFormLexicon: state => state.leads.currentFormLexicon,
    }),
    hasAvailableFilters() {
      return this.genericFilters.length > 0 || this.fieldFilters.length > 0
    },
    filterCount() {
      return this.currentFilters.filter(currentFilter => currentFilter.slug !== 'to').length
    },
    getTotalPagesCount() {
      return Math.ceil(this.currentLeadsList.total / this.currentLeadsList.itemsPerPage)
    },

    availableTags() {
      return this.genericFilters.find(filter => filter.type === 'tags')?.values || []
    },

    isForwardOpen() {
      return this.forwardOpen
    },

    getDrawerTitle() {
      if (this.currentActionDrawer === this.FORWARD_OWNER_DRAWER) {
        return this.$t('leads.individual.forward.header.title')
      }
      return this.$t('leads.individual.changeLocation.header.title')
    },
  },

  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
      setUpdating: 'backoffice/setUpdating',
      getFormsList: 'leads/getFormsList',
      getLeadsList: 'leads/getLeadsList',
      getLeadExport: 'leads/getLeadExport',
      setLeadsFilters: 'leads/setLeadsFilters',
      setCurrentFilters: 'leads/setCurrentFilters',
      setCurrentLead: 'leads/setCurrentLead',
      setCurrentForm: 'leads/setCurrentForm',
      setActionDrawerOpened: 'leads/setActionDrawerOpened',
      setCurrentActionDrawer: 'leads/setCurrentActionDrawer',
      setSearch: 'leads/setSearch',
      changeLeadStatus: 'leads/changeLeadStatus',
      deleteLeadWithUrl: 'leads/deleteLeadWithUrl',
      changeLeadOwner: 'leads/changeLeadOwner',
      changeLeadLocation: 'leads/changeLeadLocation',
      editLead: 'leads/editLead',
      getLPELocations: 'leads/getLPELocations',
      clearLPELocations: 'leads/clearLPELocations',
    }),

    async changeForm(item) {
      await this.setCurrentForm(item.id)
      await this.setCurrentFilters([])
      await this.loadLeads()
    },

    searchLeads: debounce(async function (value) {
      this.setSearch(value)
      this.resetOptions()
    }, 300),

    async loadLeads() {
      this.refreshing = true
      await this.getLeadsList({ formId: this.currentForm?.id, tableParams: this.options })

      this.refreshing = false
    },

    async filterView(filters) {
      this.setCurrentFilters(filters)
      this.resetOptions()
    },

    async searchLocations(query) {
      await this.getLPELocations(query)
    },

    cancelAction() {
      this.setHasNewValueToSave(false)
      this.mustBeEmptied = true
      this.clearLPELocations()
    },

    drawerAction() {
      this.triggerAction = true
    },

    closeDrawer() {
      this.toggleActionDrawer(false)
    },

    buildFilterFromQuery(query) {
      const rawFilter = Object.keys(query)

      return rawFilter
        .reduce((queryFilters, queryKey) => {
          if (queryKey === 'fields') {
            for (const [filterSlug, filterValue] of Object.entries(query[queryKey])) {
              queryFilters.push({
                slug: filterSlug,
                value: filterValue,
                type: 'fieldValue',
              })
            }
            return queryFilters
          }

          queryFilters.push({
            slug: queryKey,
            value: query[queryKey],
          })
          return queryFilters
        }, [])
        .filter(filter => !['search', 'formId', 'page'].includes(filter.slug))
    },

    getLeadById(id) {
      return this.currentLeadsList.rows.find(lead => lead.id === id)
    },

    getSelectedClass(item) {
      return item.id === this.currentLead.id ? 'selected' : ''
    },

    toggleFilters(bool) {
      this.filterOpen = bool
    },

    toggleIndividual(bool) {
      this.individualOpen = bool
      if (bool === false) {
        this.selectedLeadId = null
        this.removeLeadIdFromUrl()
      }
    },

    toggleActionDrawer(bool) {
      this.setActionDrawerOpened(bool)
      if (bool === false) {
        return this.resetDrawer()
      }

      this.mustBeEmptied = false
    },

    resetDrawer() {
      this.hasActions = false
      this.isForwardSaving = false
      this.isChangeLocationSaving = false
      this.mustBeEmptied = true
      this.triggerAction = false
    },

    setHasNewValueToSave(bool) {
      this.hasActions = bool
    },

    setIsOwnerInvalid(bool) {
      this.isOwnerInvalid = bool
    },

    removeLeadIdFromUrl() {
      const queryWithoutLeadId = Object.assign({}, this.$route.query)
      delete queryWithoutLeadId.leadId
      this.$router.push({ path: this.$route.path, query: queryWithoutLeadId }).catch(err => {
        return err
      })
    },

    removePageFromUrl() {
      const queryWithoutPage = Object.assign({}, this.$route.query)
      delete queryWithoutPage.page
      this.$router.push({ path: this.$route.path, query: queryWithoutPage }).catch(err => err)
    },

    changeOptions(optionChanged) {
      this.selectedLeadId = null
      this.options = { ...optionChanged }
    },
    closeIndividual() {
      this.individualOpen = false
    },
    async openIndividual(leadId) {
      this.selectedLeadId = leadId

      this.currentLeadIndexInPage = this.currentLeadsList.rows.findIndex(lead => lead.id == leadId)
      this.currentLeadsListLength = this.currentLeadsList.rows.length
      this.individualOpen = true

      this.hasPrevButton = this.currentLeadIndexInPage !== 0 || this.options.page !== 1
      this.hasNextButton =
        this.currentLeadIndexInPage !== this.currentLeadsListLength - 1 || this.options.page !== this.getTotalPagesCount

      await this.setCurrentLead({
        formId: this.currentForm ? this.currentForm.id : '',
        leadId: leadId,
      })
    },

    async exportLeads() {
      this.exportWorking = true

      await this.getLeadExport({ formId: this.currentForm ? this.currentForm.id : '', tableParams: this.options })
      this.exportWorking = false
    },

    componentItemName(item) {
      let prefix = ''

      switch (item.type) {
        case 'price':
          prefix = 'Price'
          break
        case 'date':
        case 'datepicker':
          prefix = 'Date'
          break
        case 'radio':
          prefix = 'Radio'
          break
        case 'checkbox':
          prefix = 'Checkbox'
          break
        case 'select':
          prefix = 'Multiselect'
          break
        case 'file':
          prefix = 'File'
          break
        case 'status':
          prefix = 'Chip'
          break
        case 'ChipList':
          prefix = 'ChipList'
          break
        case 'location':
          prefix = 'Location'
          break
        case 'identity':
          prefix = 'Identity'
          break
        case 'skus':
          prefix = 'Skus'
          break
        case 'actions':
          prefix = 'Actions'
          break
        case 'country':
          prefix = 'Country'
          break
        default:
          prefix = 'Text'
          break
      }
      return `LeadsListRows${prefix}`
    },

    async saveModifiedLead(modifiedLead) {
      this.setUpdating(true)
      try {
        const currentFormId = this.currentForm ? this.currentForm.id : ''
        const currentLeadId = this.$route.query.leadId
        await this.editLead({
          formId: currentFormId,
          leadId: currentLeadId,
          leadContent: modifiedLead,
        })
        await this.setCurrentLead({
          formId: currentFormId,
          leadId: currentLeadId,
        })
        await this.getLeadsList({ formId: currentFormId, tableParams: this.options })
        this.setAlert({
          color: 'success',
          text: this.$t('leads.notification.success'),
        })
      } catch (error) {
        formatErrorMessage(error)
        this.setAlert({
          color: 'error',
          text: formatErrorMessage(error),
        })
      }
      this.setUpdating(false)
    },

    async changeCurrentLead(payload) {
      if (this.currentLeadLoading) return
      if (!this.hasPrevButton && payload === 'previous') return
      if (!this.hasNextButton && payload === 'next') return

      if ((this.currentLeadIndexInPage + 1) % this.options.itemsPerPage === 0 && payload === 'next') {
        this.currentLeadIndexInPage = 0
        this.options.page++
      } else if (this.currentLeadIndexInPage === 0 && payload === 'previous') {
        this.currentLeadIndexInPage = this.options.itemsPerPage - 1
        this.options.page--
      } else {
        if (payload === 'next') {
          this.currentLeadIndexInPage++
          this.openIndividual(this.currentLeadsList.rows[this.currentLeadIndexInPage].id)
        } else {
          this.currentLeadIndexInPage--
          this.openIndividual(this.currentLeadsList.rows[this.currentLeadIndexInPage].id)
        }
      }
    },

    async changeLocation(payload) {
      try {
        await this.changeLeadLocation({ url: payload.url, locationId: payload.locationId })
        this.setAlert({
          color: 'success',
          text: this.$t('leads.notification.success'),
        })
        this.resetOptions()
        this.toggleActionDrawer(false)
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
    },

    async forward(payload) {
      if (payload.email != null) {
        try {
          await this.changeLeadOwner({ url: payload.url, notify: payload.notify, email: payload.email })
          this.setAlert({
            color: 'success',
            text: this.$t('leads.notification.success'),
          })
          this.resetOptions()
          this.toggleActionDrawer(false)
        } catch {
          this.setAlert({
            color: 'error',
            text: this.$t('error.notification.default'),
          })
        }
      }
    },

    async executeAction({ url, status, id }) {
      if (status === 'forward') {
        this.setActionDrawerOpened(true)
        this.setCurrentActionDrawer(this.FORWARD_OWNER_DRAWER)
        this.activatedLead = this.getLeadById(id)
        return
      }

      if (status === 'changeLocation') {
        this.setActionDrawerOpened(true)
        this.setCurrentActionDrawer(this.CHANGE_LOCATION_DRAWER)
        this.activatedLead = this.getLeadById(id)
        return
      }

      if (status === 'delete') {
        this.activatedLead = this.getLeadById(id)
        this.displayDeleteLeadConfirmOverlay = true
        return
      }

      try {
        const i18StatusExist = this.$te(`leads.notification.${status}`)

        await this.changeLeadStatus(url)
        await this.loadLeads()

        if (this.$route.query.leadId) {
          await this.setCurrentLead({
            formId: this.currentForm ? this.currentForm.id : '',
            leadId: this.$route.query.leadId,
          })
        }
        this.setAlert({
          color: 'success',
          text: this.$t(`leads.notification.${i18StatusExist ? status : 'success'}`),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
    },

    resetOptions() {
      this.options = {
        ...this.options,
        page: 1,
      }
    },

    cancelLeadDeletion() {
      this.displayDeleteLeadConfirmOverlay = false
      this.activatedLead = null
    },

    async deleteLead() {
      this.confirmDeleteLeadOverlayisLoading = true
      try {
        await this.deleteLeadWithUrl(this.activatedLead.actions.delete)
        await this.loadLeads()
        this.setAlert({
          color: 'success',
          text: this.$t(`leads.notification.delete`),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.activatedLead = null
      this.confirmDeleteLeadOverlayisLoading = false
      this.displayDeleteLeadConfirmOverlay = false
    },
  },
}
</script>

<style lang="scss">
.leads-list {
  .v-select__selection--comma {
    overflow: visible;
    font-weight: 600;
  }

  &__content {
    @apply tw-w-full tw-h-full tw-flex tw-flex-col;

    transition: padding-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &__platform-leads {
      &__select {
        input {
          width: 0 !important;
        }

        fieldset {
          border: none;
        }

        border: 0;
        background-color: #efefef;
      }
    }

    &__main {
      @apply tw-w-full tw-h-full tw-flex tw-flex-col;
      &--expanded {
        @media (min-width: map-get($grid-breakpoints, 'md')) {
          padding-left: 256px;
        }
      }
      &__toolbar {
        @apply tw-flex tw-items-center tw-gap-4 tw-p-4 sm:tw-px-6;

        border-bottom: 1px solid map-deep-get($material-light, 'banner', 'border');
        height: $toolbar-height;

        &.theme--dark {
          border-bottom: 1px solid map-deep-get($material-dark, 'banner', 'border');
        }
      }

      &__table-container {
        @apply tw-absolute tw-right-0 tw-bottom-0 tw-left-0;

        top: 0;

        & mark {
          background-color: #fdde53;
          padding: 0 1px;
          font-weight: 600;
        }

        &--search {
          top: $toolbar-height;
        }

        &__table tbody tr:hover {
          cursor: pointer;
        }

        &__table {
          &.theme--dark {
            & th:last-child,
            & td:last-child,
            & th:nth-last-child(2),
            & td:nth-last-child(2) {
              background-color: $interface-dark-grade-1-color;
            }
          }

          & th:last-child,
          & td:last-child,
          & th:nth-last-child(2),
          & td:nth-last-child(2) {
            background: white;
            position: sticky;
            min-width: 100px;
          }

          & th:last-child,
          & td:last-child {
            right: 0;
          }

          & th:nth-last-child(2),
          & td:nth-last-child(2) {
            right: 100px;
            border-left: thin solid rgba(0, 0, 0, 0.12);
            &::before {
              content: '';
              width: 30px;
              left: 0;
              background: black;
            }
          }

          & tr {
            &:hover {
              td:last-child,
              td:nth-last-child(2),
              th:last-child,
              th:nth-last-child(2) {
                background-color: inherit;
              }
            }
          }

          & td {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .selected {
    background: #eeeeee;
  }

  .truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    display: block;
  }

  .truncated * {
    display: inline;
  }

  .empty-field {
    color: #a5a5a5;
  }
}
</style>
