<template>
  <div>
    <span v-if="isEmpty(field[fieldKey])" class="empty-field">{{ $t('label.empty') }}</span>
    <UiCountryLabel v-else :label="field[fieldKey]" :search="search" />
  </div>
</template>

<script>
import UiCountryLabel from '@/components/UI/CountryLabel.vue'
import { isEmpty } from '@/utils/helper.util'

export default {
  name: 'LeadsListRowsCountry',
  components: {
    UiCountryLabel,
  },
  data: () => ({
    isEmpty,
  }),
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldKey: {
      type: String,
      require: true,
    },
    search: {
      type: String,
      required: false,
    },
  },
}
</script>
