<template>
  <div class="truncated">
    <span class="empty-field" v-if="!parsedValue">{{ $t('label.empty') }}</span>
    <span v-else v-dompurify-html="highlightText(parsedValue, search)" />
  </div>
</template>

<script>
import { highlightText } from '@/utils/formatter.util'
import { isEmpty, formattedPrice } from '@/utils/helper.util'

export default {
  name: 'LeadsListRowsPrice',
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldKey: {
      type: String,
      require: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    search: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    highlightText,
    isEmpty,
  }),
  computed: {
    parsedValue() {
      if (!this.field[this.fieldKey] || !this.field[this.fieldKey].value) return undefined
      return formattedPrice({
        number: this.field[this.fieldKey].value,
        currency: this.field[this.fieldKey].currency,
      })
    },
  },
}
</script>
