<template>
  <v-chip :color="statusOptions.chipColor" :text-color="statusOptions.textColor">
    {{ $t(`leads.list.table.status.${field[fieldKey]}`) }}
  </v-chip>
</template>

<script>
import { LeadStatus } from '@/config/leads.config'

export default {
  name: 'LeadsListRowsChip',
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldKey: {
      type: String,
      require: true,
    },
  },

  data: () => ({
    initialStatusColor: {
      textColor: 'black',
      chipColor: 'white',
    },
  }),

  computed: {
    statusOptions() {
      return this.getStatusColor(this.field[this.fieldKey])
    },
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case LeadStatus.NEW:
          return {
            textColor: 'white',
            chipColor: '#a678e4',
          }
        case LeadStatus.ACCEPTED:
          return {
            textColor: 'white',
            chipColor: 'blue',
          }
        case LeadStatus.CONVERTED:
          return {
            textColor: 'white',
            chipColor: 'green',
          }
        case LeadStatus.LOST:
          return {
            textColor: 'white',
            chipColor: 'red',
          }
        default:
          return this.initialStatusColor
      }
    },
  },
}
</script>
