<template>
  <div class="individual-leads-lead-values">
    <div v-if="lead.createdAt" class="tw-flex tw-flex-col tw-mb-2">
      <div class="row-title">{{ $t('leads.list.table.headers.createdAt') }}</div>

      <LeadsIndividualFieldsDate
        :options="{
          format: 'L LT',
        }"
        :field="formattedField('createdAt')"
        :locale="$i18n.locale"
        :editable="false"
      />
      <v-divider v-if="!editMode" class="ui-separator"></v-divider>
    </div>

    <div v-if="lead.owner" class="tw-flex tw-flex-col tw-mb-2">
      <div class="row-title">{{ $t('leads.list.table.headers.owner') }}</div>

      <LeadsIndividualFieldsText :field="formattedField('owner')" :editable="false" />
      <v-divider v-if="!editMode" class="ui-separator"></v-divider>
    </div>

    <div class="tw-flex tw-flex-col tw-mb-2">
      <div class="row-title">{{ capitalize(lexicon.tags) }}</div>

      <LeadsIndividualFieldsChipList
        :field="formattedField('tags')"
        :editMode="editMode"
        :editable="true"
        :possibleValues="availableTags"
        @updateField="$emit('updateValue', $event)"
      />
      <v-divider v-if="!editMode" class="ui-separator"></v-divider>
    </div>

    <div v-if="lead.price !== undefined" class="tw-flex tw-flex-col tw-mb-2">
      <div class="row-title">{{ $t('leads.list.table.headers.price') }}</div>
      <LeadsIndividualFieldsPrice
        :field="formattedField('price')"
        :locale="$i18n.locale"
        :editMode="editMode"
        :editable="true"
        @updateField="$emit('updateValue', $event)"
      />
      <v-divider v-if="!editMode" class="ui-separator"></v-divider>
    </div>

    <div v-if="lead.locationName" class="tw-flex tw-flex-col tw-mb-2">
      <div class="row-title">{{ capitalize(lexicon.location) }}</div>

      <LeadsIndividualFieldsText :field="formattedField('locationName')" :editable="false" />
      <v-divider v-if="!editMode" class="ui-separator"></v-divider>
    </div>

    <div v-if="lead.company" class="tw-flex tw-flex-col tw-mb-2">
      <div class="row-title">{{ capitalize(lexicon.company) }}</div>

      <LeadsIndividualFieldsText :field="formattedField('company')" :editable="false" />
      <v-divider v-if="!editMode" class="ui-separator"></v-divider>
    </div>

    <div v-if="lead.address" class="tw-flex tw-flex-col tw-mb-2">
      <div class="row-title">{{ $t('leads.list.table.headers.address') }}</div>
      <LeadsIndividualFieldsText :field="formattedField('address')" :editable="false" />
      <v-divider v-if="!editMode" class="ui-separator"></v-divider>
    </div>

    <div v-if="isProdutForm" class="tw-flex tw-flex-col tw-mb-2">
      <div class="row-title">{{ capitalize(lexicon.skus) }}</div>

      <LeadsIndividualFieldsSkus
        :field="formattedField('skus')"
        :editMode="editMode"
        :editable="true"
        @updateField="$emit('updateValue', $event)"
      />
      <v-divider v-if="!editMode" class="ui-separator"></v-divider>
    </div>

    <div class="tw-flex tw-flex-col tw-mb-2">
      <div class="row-title">{{ $t('leads.individual.internalNote.title') }}</div>
      <LeadsIndividualFieldsTextarea
        :field="formattedField('internalNote')"
        :editable="true"
        :editMode="editMode"
        @updateField="$emit('updateValue', $event)"
      />
      <v-divider v-if="!editMode" class="ui-separator"></v-divider>
    </div>
  </div>
</template>

<script>
import LeadsIndividualFieldsText from '@/components/Leads/Individual/Fields/Text'
import LeadsIndividualFieldsChipList from '@/components/Leads/Individual/Fields/ChipList'
import LeadsIndividualFieldsDate from '@/components/Leads/Individual/Fields/Date'
import LeadsIndividualFieldsPrice from '@/components/Leads/Individual/Fields/Price'
import LeadsIndividualFieldsSkus from '@/components/Leads/Individual/Fields/Skus'
import LeadsIndividualFieldsTextarea from '@/components/Leads/Individual/Fields/Textarea'
import { capitalize } from '@/utils/formatter.util'

export default {
  name: 'LeadsIndividualLeadValues',
  components: {
    LeadsIndividualFieldsSkus,
    LeadsIndividualFieldsText,
    LeadsIndividualFieldsChipList,
    LeadsIndividualFieldsDate,
    LeadsIndividualFieldsPrice,
    LeadsIndividualFieldsTextarea,
  },

  props: {
    lead: {
      type: Object,
      required: false,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    availableTags: {
      type: Array,
      required: true,
    },
    isProdutForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    lexicon: {
      type: Object,
    },
  },
  methods: {
    formattedField(fieldKey) {
      return {
        value: this.lead[fieldKey],
        slug: fieldKey,
      }
    },
    capitalize,
  },
}
</script>

<style lang="scss" scoped>
.ui-separator {
  @apply tw-mt-2 tw-border-gray-200 dark:tw-border-opacity-10;
}

.row-title {
  @apply tw-text-sm;
  opacity: 0.5;
}
</style>
