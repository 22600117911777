<template>
  <v-toolbar
    :color="$vuetify.theme.dark ? 'hsl(222, 36%, 10%)' : '#F5F7FA'"
    class="tw-w-full"
    height="56"
    :elevation="2"
  >
    <slot name="content"></slot>
  </v-toolbar>
</template>

<script>
export default {
  name: 'UiDrawerHeader',
}
</script>
