<template>
  <div class="chip-list">
    <span class="empty-field" v-if="isEmpty(fieldValues)">{{ $t('label.empty') }}</span>
    <span v-else>
      <v-chip small class="chip-list__chip" v-for="(chip, index) in fieldValues" :key="index">
        <span v-dompurify-html="highlightText(chip, search)" />
      </v-chip>
    </span>
  </div>
</template>

<script>
import { highlightText } from '@/utils/formatter.util'
import { toArray, isEmpty } from '@/utils/helper.util'

export default {
  name: 'LeadsListRowsChipList',
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldKey: {
      type: String,
      require: true,
    },
    search: {
      type: String,
      required: false,
    },
  },
  computed: {
    fieldValues() {
      return toArray(this.field[this.fieldKey])
    },
  },
  data: () => ({
    isEmpty,
    highlightText,
  }),
}
</script>

<style lang="scss">
.chip-list {
  white-space: nowrap;

  &__chip {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
