<template>
  <UiDrawer class="action-drawer" :open="open" @toggle="toggle">
    <template v-slot:content>
      <UiDrawerHeader>
        <template v-slot:content>
          <div class="tw-text-bold tw-text-lg">{{ title }}</div>
          <v-spacer />
          <div>
            <span class="tw-ml-2">
              <v-btn icon outlined small @click="toggle(false)">
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </span>
          </div>
        </template>
      </UiDrawerHeader>

      <div class="action-drawer__content-container">
        <slot name="content"></slot>
      </div>

      <UiDrawerFooter v-if="hasActions">
        <template v-slot:content>
          <div class="action-drawer__button-container">
            <v-btn
              color="primary"
              type="submit"
              class="action-drawer__button-container__button-cancel"
              :loading="isSaving"
              :disabled="isSaving || isOwnerInvalid"
              outlined
              @click="cancelAction"
            >
              {{ $t('button.cancel') }}
            </v-btn>

            <v-btn
              color="primary"
              type="submit"
              class="action-drawer__button-container__button-submit"
              :loading="isSaving"
              :disabled="isSaving || isOwnerInvalid"
              @click="drawerAction"
            >
              <v-icon>{{ icons.mdiContentSaveCheckOutline }}</v-icon>
              <span>{{ $t('button.save') }}</span>
            </v-btn>
          </div>
        </template></UiDrawerFooter
      >
    </template>
  </UiDrawer>
</template>

<script>
import { mdiClose, mdiContentSaveCheckOutline } from '@mdi/js'
import UiDrawer from '@/components/UI/Drawer/Drawer.vue'
import UiDrawerHeader from '@/components/UI/Drawer/DrawerElements/DrawerHeader.vue'
import UiDrawerFooter from '@/components/UI/Drawer/DrawerElements/DrawerFooter.vue'
export default {
  name: 'UiActionDrawer',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'title',
    },
    hasActions: {
      type: Boolean,
      required: true,
    },
    isOwnerInvalid: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    isSaving: false,
    icons: {
      mdiClose,
      mdiContentSaveCheckOutline,
    },
  }),
  components: {
    UiDrawer,
    UiDrawerHeader,
    UiDrawerFooter,
  },
  methods: {
    toggle(bool) {
      this.$emit('toggle', bool)
    },
    drawerAction() {
      this.$emit('drawerAction')
    },
    cancelAction() {
      this.$emit('cancelAction')
    },
  },
}
</script>

<style lang="scss" scoped>
.action-drawer {
  &__content-container {
    padding-bottom: 50px;
  }

  &__button-container {
    background-color: #fff;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 15px;
    padding-bottom: 15px;
    bottom: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    text-align: center;
    width: 100%;
  }
}
</style>
