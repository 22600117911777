<template>
  <div class="tw-whitespace-nowrap">
    <span v-if="isEmpty(itemValues)" class="empty-field">{{ $t('label.empty') }}</span>

    <span v-else v-for="(date, dateIndex) in itemValues" :key="dateIndex">
      <span v-dompurify-html="highlightText(formatedDate(date, options.format), search)" />
      <span v-if="itemValues.length > 0 && dateIndex < itemValues.length - 1">&nbsp;-&nbsp;</span>
    </span>
  </div>
</template>

<script>
import { highlightText } from '@/utils/formatter.util'
import { formatedDate } from '@/utils/date.util'
import { toArray, isEmpty } from '@/utils/helper.util'

export default {
  name: 'LeadsListRowsDate',
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldKey: {
      type: String,
      require: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    search: {
      type: String,
      required: false,
    },
  },
  computed: {
    itemValues() {
      return toArray(this.field[this.fieldKey])
    },
  },
  data: () => ({
    isEmpty,
    formatedDate,
    highlightText,
  }),
}
</script>
