<template>
  <div class="truncated">
    <div v-if="isBooleanValue">
      <UiBooleanIcon :value="field[fieldKey]" />
    </div>

    <div v-else>
      <span v-if="isEmpty(field[fieldKey])" class="empty-field">{{ $t('label.empty') }}</span>

      <span v-for="(currentValue, index) in field[fieldKey]" :key="index">
        <span v-dompurify-html="highlightText(currentValue, search)" />
        <span v-if="index < field[fieldKey].length - 1">,&nbsp;</span>
      </span>
    </div>
  </div>
</template>

<script>
import { highlightText } from '@/utils/formatter.util'
import { isEmpty } from '@/utils/helper.util'
import UiBooleanIcon from '@/components/UI/BooleanIcon.vue'
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js'

export default {
  name: 'LeadsListRowsCheckbox',
  components: {
    UiBooleanIcon,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldKey: {
      type: String,
      require: true,
    },
    search: {
      type: String,
      required: false,
    },
  },
  computed: {
    isBooleanValue() {
      return typeof this.field[this.fieldKey] === 'boolean'
    },
  },
  data: () => ({
    icons: {
      mdiCheckCircle,
      mdiCloseCircle,
    },
    highlightText,
    isEmpty,
  }),
}
</script>
