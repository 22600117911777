<template>
  <div>
    <span v-if="isEmpty(field[fieldKey])" class="empty-field">{{ $t('label.empty') }}</span>

    <v-btn
      v-else-if="!isEmpty(field[fieldKey].url)"
      x-small
      outlined
      color="primary"
      @click="goTo(field[fieldKey].url)"
      :title="$t('leads.list.table.actions.image.title')"
    >
      <v-icon light>{{ icons.mdiMagnifyPlusOutline }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mdiMagnifyPlusOutline } from '@mdi/js'
import { isEmpty } from '@/utils/helper.util'

export default {
  name: 'LeadsListRowsFile',
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldKey: {
      type: String,
      require: true,
    },
  },
  data: () => ({
    isEmpty,
    icons: {
      mdiMagnifyPlusOutline,
    },
  }),
  methods: {
    goTo(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
