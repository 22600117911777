<template>
  <div class="truncated">
    <span class="empty-field" v-if="isEmpty(itemValues)">{{ $t('label.empty') }}</span>

    <span v-else v-for="(selection, index) in itemValues" :key="index">
      <span v-dompurify-html="highlightText(selection, search)" />
      <span v-if="itemValues.length > 0 && index < itemValues.length - 1">,&nbsp;</span>
    </span>
  </div>
</template>

<script>
import { highlightText } from '@/utils/formatter.util'
import { toArray, isEmpty } from '@/utils/helper.util'
export default {
  name: 'LeadsListRowsMultiselect',
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldKey: {
      type: String,
      require: true,
    },
    search: {
      type: String,
      required: false,
    },
  },
  computed: {
    itemValues() {
      return toArray(this.field[this.fieldKey])
    },
  },
  data: () => ({
    highlightText,
    isEmpty,
  }),
}
</script>
