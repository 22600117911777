<template>
  <div v-if="editMode" class="tw-mt-3">
    <v-select
      v-model="editedValue"
      solo
      flat
      outlined
      :items="countriesList"
      item-value="code"
      hide-details="auto"
      :placeholder="$t('label.empty')"
    >
      <template #item="{ item }">
        <UiCountryLabel :label="item.code" />
      </template>
      <template #selection="{ item }">
        <UiCountryLabel :label="item.code" />
      </template>
    </v-select>
  </div>
  <div v-else class="tw-whitespace-pre-line">
    <span v-if="isEmpty(field.value)" class="empty-field">{{ $t('label.empty') }}</span>

    <UiCountryLabel v-else :label="item.code" />
  </div>
</template>

<script>
import { isEmpty } from '@/utils/helper.util'
import UiCountryLabel from '@/components/UI/CountryLabel.vue'

import { countries, flagCountries } from '@/config/countries.config'

export default {
  name: 'LeadsIndividualFieldsCountry',
  components: { UiCountryLabel },
  props: {
    field: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isEmpty,
    editedValue: null,
  }),
  created() {
    if (this.field.value) {
      this.editedValue = this.field.value
    }
  },
  computed: {
    fieldValues() {
      return this.field.value
    },

    countriesList() {
      return countries[this.$i18n.locale]
    },
  },
  watch: {
    editedValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.$emit('updateField', { [this.field.slug]: this.editedValue })
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    getCountryFlag(countryCode) {
      const country = flagCountries.find(country => country.code === countryCode)
      return country ? country.src : ''
    },
    getCountryName(countryCode) {
      const country = countries[this.$i18n.locale].find(c => c.code === countryCode)
      return country ? country.name : this.$t(`profilehub.dashboard.segments.label.${countryCode || 'other'}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-label {
  @apply tw-inline-flex tw-items-center tw-gap-2;

  margin-bottom: 2px;
}
</style>
