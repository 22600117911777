<template>
  <div class="truncated">
    <span v-if="isEmpty(field[fieldKey])" class="empty-field">{{ $t('label.empty') }}</span>
    <span v-else v-dompurify-html="highlightText(field[fieldKey], search)" />
  </div>
</template>

<script>
import { highlightText } from '@/utils/formatter.util'
import { isEmpty } from '@/utils/helper.util'

export default {
  name: 'LeadsListRowsRadio',
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldKey: {
      type: String,
      require: true,
    },
    search: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    highlightText,
    isEmpty,
  }),
}
</script>
