<template>
  <div>
    <span class="empty-field" v-if="isEmpty(stringifiedIdentity)">{{ $t('label.empty') }}</span>
    <span v-else v-dompurify-html="highlightText(stringifiedIdentity, search)"></span>
  </div>
</template>

<script>
import { highlightText } from '@/utils/formatter.util'
import { isEmpty } from '@/utils/helper.util'

export default {
  name: 'LeadsListRowsIdentity',
  props: {
    field: {
      type: Object,
      required: true,
    },
    search: {
      type: String,
      required: false,
    },
  },
  computed: {
    stringifiedIdentity() {
      let identityDisplayed = ''

      if (this.field.identity?.firstname) identityDisplayed += this.field.identity.firstname
      if (this.field.identity?.lastname) identityDisplayed += ` ${this.field.identity.lastname}`
      if (identityDisplayed === '') identityDisplayed += this.field.identity?.email || ''

      return identityDisplayed.trim()
    },
  },
  data: () => ({
    isEmpty,
    highlightText,
  }),
}
</script>
