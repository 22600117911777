<template>
  <div class="skus tw-whitespace-nowrap">
    <span class="empty-field" v-if="isEmpty(fieldValues)">{{ $t('label.empty') }}</span>

    <v-chip v-else small class="chip tw-mr-1">
      <span class="skus__truncated" v-dompurify-html="highlightText(fieldValues[0], search)" />
    </v-chip>

    <v-chip v-if="fieldValues.length > 1" small class="chip">+{{ fieldValues.length - 1 }}</v-chip>
  </div>
</template>

<script>
import { highlightText } from '@/utils/formatter.util'
import { toArray, isEmpty } from '@/utils/helper.util'
export default {
  name: 'LeadsListRowsSkus',
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldKey: {
      type: String,
      require: true,
    },
    search: {
      type: String,
      required: false,
    },
  },
  computed: {
    fieldValues() {
      return toArray(this.field[this.fieldKey])
    },
  },
  data: () => ({
    highlightText,
    isEmpty,
  }),
}
</script>

<style lang="scss">
.skus {
  &__truncated {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .v-chip__content {
      display: inline !important;
    }
  }
}
</style>
