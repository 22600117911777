<template>
  <div class="truncated tw-flex tw-justify-between tw-items-center">
    <span v-if="isEmpty(field[fieldKey])" class="empty-field">{{ $t('label.empty') }}</span>
    <span v-else v-dompurify-html="highlightText(field[fieldKey], search)" />

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }"
        ><v-icon small v-if="field.address" v-bind="attrs" v-on="on">
          {{ icons.mdiHelpCircleOutline }}
        </v-icon>
      </template>
      <span>{{ field.address }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { highlightText } from '@/utils/formatter.util'
import { mdiHelpCircleOutline } from '@mdi/js'
import { isEmpty } from '@/utils/helper.util'

export default {
  name: 'LeadsListRowsLocation',
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldKey: {
      type: String,
      require: true,
    },
    search: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    highlightText,
    isEmpty,
    icons: {
      mdiHelpCircleOutline,
    },
  }),
}
</script>
