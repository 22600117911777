<template>
  <UiExpandableMenu :actions="actionList" :links="linkList" @executeAction="openActions" />
</template>

<script>
import UiExpandableMenu from '@/components/UI/ExpandableMenu.vue'
import {
  mdiAccountCheckOutline,
  mdiAccountOffOutline,
  mdiAccountQuestionOutline,
  mdiAccountClockOutline,
  mdiPhoneOutline,
  mdiEmailOutline,
  mdiAccountConvertOutline,
  mdiHomeSwitchOutline,
  mdiDeleteOutline,
} from '@mdi/js'

export default {
  name: 'LeadsListRowsActions',
  components: {
    UiExpandableMenu,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    availableActions: ['lose', 'convert', 'accept', 'phone', 'email', 'forward', 'changeLocation', 'delete'],
    icons: {
      mdiAccountCheckOutline,
      mdiAccountOffOutline,
      mdiAccountQuestionOutline,
      mdiAccountClockOutline,
      mdiAccountConvertOutline,
      mdiPhoneOutline,
      mdiEmailOutline,
      mdiHomeSwitchOutline,
      mdiDeleteOutline,
    },
  }),

  computed: {
    actionList() {
      const formattedActions = []

      for (const action in this.field.actions) {
        if (this.isAvailableActionOrLink(action)) {
          formattedActions.push({
            slug: action,
            icon: this.getIconByValue(action),
            label: this.$t(`leads.list.table.actions.${action}`),
          })
        }
      }
      return formattedActions
    },
    linkList() {
      const formattedLinks = []

      for (const link in this.field.links) {
        if (this.isAvailableActionOrLink(link)) {
          formattedLinks.push({
            icon: this.getIconByValue(link),
            label: this.$t(`leads.list.table.links.${link}`),
            url: this.field.links[link],
          })
        }
      }
      return formattedLinks
    },
    isDisabled() {
      return Object.keys(this.field.actions).length === 0
    },
  },

  methods: {
    isAvailableActionOrLink(slug) {
      return this.availableActions.includes(slug)
    },

    openActions(slug) {
      this.$emit('executeAction', { url: this.field.actions[slug], status: slug, id: this.field.id || null })
    },

    getIconByValue(slug) {
      switch (slug) {
        case 'lose':
          return this.icons.mdiAccountOffOutline
        case 'convert':
          return this.icons.mdiAccountCheckOutline
        case 'accept':
          return this.icons.mdiAccountClockOutline
        case 'phone':
          return this.icons.mdiPhoneOutline
        case 'email':
          return this.icons.mdiEmailOutline
        case 'forward':
          return this.icons.mdiAccountConvertOutline
        case 'changeLocation':
          return this.icons.mdiHomeSwitchOutline
        case 'delete':
          return this.icons.mdiDeleteOutline

        default:
          return this.icons.mdiAccountQuestionOutline
      }
    },
  },
}
</script>

<style lang="scss">
.action-label strong {
  font-weight: 600;
}
</style>
