<template>
  <div>
    <div class="lead-history">
      <div
        v-for="(entry, index) in history"
        :key="index"
        class="tw-flex tw-mb-6 tw-items-center lead-history__entry"
        :class="{ 'has-chip': hasChip(entry) }"
      >
        <div>
          <v-icon
            v-if="entry.type === 'status'"
            :color="getStatusChipColor(entry)"
            class="lead-history__entry__status-chip tw-rounded-full"
            >{{ getChipIcon(entry.type) }}</v-icon
          >
          <v-icon
            v-else
            class="lead-history__entry__chip tw-rounded-full"
            :class="[
              {
                'tw-bg-gray-100': entry.type !== 'mail',
                'tw-bg-blue-100': entry.type === 'mail',
              },
            ]"
            >{{ getChipIcon(entry.type) }}</v-icon
          >
        </div>
        <div v-if="entry.type === 'status'" class="tw-font-medium tw-ml-4 tw-text-sm tw-flex-grow">
          {{ $t(`leads.individual.history.status.${entry.status}`) }}
        </div>
        <div v-if="entry.type === 'assign'" class="tw-font-medium tw-ml-4 tw-text-sm tw-flex-grow">
          <span v-if="entry.owner">{{ $t('leads.individual.history.assign', { email: entry.owner }) }}</span>
          <span v-else>{{ $t('leads.individual.history.unassign') }}</span>
        </div>
        <div v-if="entry.type === 'forward'" class="tw-font-medium tw-ml-4 tw-text-sm tw-flex-grow">
          <span>{{ $t('leads.individual.history.forward', { email: entry.owner }) }}</span>
        </div>
        <div v-if="entry.type === 'location_change'" class="tw-font-medium tw-ml-4 tw-text-sm tw-flex-grow">
          <span>{{
            $t('leads.individual.history.locationChange', { location: entry.newLocation && entry.newLocation.name })
          }}</span>
        </div>
        <div v-if="entry.type === 'reject'" class="tw-font-medium tw-ml-4 tw-text-sm tw-flex-grow">
          <span>{{ $t('leads.individual.history.reject', { email: entry.user.email }) }}</span>
        </div>
        <div v-else-if="entry.type === 'mail'" class="tw-font-medium tw-ml-4 tw-text-sm tw-flex-grow">
          {{ getMailName(entry.mailType) }}
          <br />
          <span class="tw-text-sm tw-font-normal">{{
            $t('leads.individual.history.mail.label', { email: entry.mail.to })
          }}</span>
        </div>
        <div v-else-if="entry.type === 'webhook'" class="tw-flex-grow tw-font-medium tw-ml-4 tw-text-sm">
          {{ $t('leads.individual.history.webhook.label', { url: entry.url }) }}
        </div>
        <div v-if="entry.type === 'edit'" class="tw-flex-grow tw-font-medium tw-ml-4 tw-text-sm">
          <span>{{ updatedAttributesList(entry) }}</span> {{ $t('leads.individual.history.edit.label') }}
        </div>
        <v-spacer />
        <div
          class="tw-w-36 tw-flex-shrink-0 tw-text-right tw-ml-4 tw-text-xs tw-text-gray-400 lead-history__entry__date"
        >
          <div>
            {{ formatedDate(entry.date, 'LT L') }}
          </div>
          <div v-if="entry.type === 'mail'" class="tw-mt-1">
            <v-chip class="tw-bg-gray-100" v-if="entry.mail.state.status !== 'error'" disabled color="#eeeeee">
              <v-icon small left>
                {{ getMailChipIcon(entry) }}
              </v-icon>
              <span class="tw-text-sm">{{ entry.mail.state.status }}</span>
            </v-chip>
            <v-tooltip v-else top transition="fade-transition">
              <template v-slot:activator="{ on: tooltip }">
                <v-chip class="tw-bg-red-100" color="#eeeeee" v-on="{ ...tooltip }">
                  <v-icon small left>
                    {{ getMailChipIcon(entry) }}
                  </v-icon>
                  <span class="tw-text-sm">{{ entry.mail.state.status }}</span>
                </v-chip>
              </template>
              {{ $t(`leads.individual.history.mail.error.${entry.mail.state.errorMessage || 'Unknown'}`) }}
            </v-tooltip>
          </div>
          <div v-else-if="entry.type === 'webhook'" class="tw-mt-1">
            <v-tooltip top transition="fade-transition">
              <template v-slot:activator="{ on: tooltip }">
                <v-chip
                  :class="[
                    {
                      'tw-bg-gray-100': entry.statusCode < 400,
                      'tw-bg-red-100': entry.statusCode >= 400,
                    },
                  ]"
                  class="tw-opacity-60"
                  color="#eeeeee"
                  v-on="{ ...tooltip }"
                >
                  <v-icon small left>
                    {{ getWebhookChipIcon(entry.statusCode, entry.retry) }}
                  </v-icon>
                  <span class="tw-text-sm">{{ entry.statusCode }}</span>
                </v-chip>
              </template>
              <div v-if="entry.retry">{{ $t('leads.individual.history.webhook.retrying') }}</div>
              <div v-if="entry.errorMessage">
                {{ $t('leads.individual.history.webhook.errorLabel') }}
                {{ entry.errorMessage }}
              </div>
              <div class="tw-pt-1">{{ entry.url }}</div>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatedDate } from '@/utils/date.util'
import { capitalize } from '@/utils/formatter.util'
import {
  mdiSync,
  mdiEmailOpenOutline,
  mdiCircleEditOutline,
  mdiEmailOutline,
  mdiCogSyncOutline,
  mdiAlertOutline,
  mdiCheckOutline,
  mdiCircle,
  mdiAccountConvertOutline,
  mdiHomeSwitchOutline,
  mdiAccountCheckOutline,
  mdiAccountOffOutline,
  mdiHelpCircleOutline,
} from '@mdi/js'
import { LeadStatus } from '@/config/leads.config'

export default {
  name: 'LeadsIndividualHistory',

  props: {
    history: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data: () => ({
    formatedDate,
    icons: {
      mdiSync,
      mdiCircleEditOutline,
      mdiEmailOpenOutline,
      mdiEmailOutline,
      mdiCogSyncOutline,
      mdiCheckOutline,
      mdiAlertOutline,
      mdiCircle,
      mdiAccountConvertOutline,
      mdiHomeSwitchOutline,
      mdiAccountCheckOutline,
      mdiAccountOffOutline,
      mdiHelpCircleOutline,
    },
  }),
  methods: {
    updatedAttributesList(entry) {
      let updatedFieldsFormatted = entry.updatedFields ? entry.updatedFields : ['unknown']
      updatedFieldsFormatted = updatedFieldsFormatted.map(updatedField => this.getFieldTranslation(updatedField))

      updatedFieldsFormatted[0] = capitalize(updatedFieldsFormatted[0])

      return updatedFieldsFormatted.join(', ')
    },
    hasChip(entry) {
      return entry.mail || entry.statusCode
    },
    getMailName(mailType) {
      return mailType
        ? this.$t(`leads.individual.history.mail.type.${mailType}`)
        : this.$t('leads.individual.history.mail.name')
    },
    getFieldTranslation(field) {
      let fieldTranslationKey = ['identity', 'fieldsValues', 'tags', 'price', 'internalNote'].includes(field)
        ? field
        : 'default'
      return this.$t(`leads.individual.history.edit.${fieldTranslationKey}`)
    },
    getStatusChipColor(entry) {
      switch (entry.status) {
        case LeadStatus.NEW:
          return '#a678e4'
        case LeadStatus.ACCEPTED:
          return '#2196f3'
        case LeadStatus.CONVERTED:
          return '#4caf50'
        case LeadStatus.LOST:
          return '#f44336'
        default:
          return '#000000'
      }
    },
    getChipIcon(type) {
      switch (type) {
        case 'mail':
          return this.icons.mdiEmailOutline
        case 'edit':
          return this.icons.mdiCircleEditOutline
        case 'webhook':
          return this.icons.mdiSync
        case 'forward':
          return this.icons.mdiAccountConvertOutline
        case 'location_change':
          return this.icons.mdiHomeSwitchOutline
        case 'assign':
          return this.icons.mdiAccountCheckOutline
        case 'reject':
          return this.icons.mdiAccountOffOutline

        default:
          return this.icons.mdiCircle
      }
    },
    getMailChipIcon(entry) {
      switch (entry.mail.state.status) {
        case 'opened':
          return this.icons.mdiEmailOpenOutline
        case 'accepted':
          return this.icons.mdiCogSyncOutline
        case 'sent':
          return this.icons.mdiEmailOutline
        case 'error':
          return this.icons.mdiAlertOutline
        default:
          return this.icons.mdiAlertOutline
      }
    },
    getWebhookChipIcon(statusCode, retry = false) {
      if (statusCode < 400) {
        return this.icons.mdiCheckOutline
      }
      if (retry) {
        return this.icons.mdiSync
      }
      return this.icons.mdiAlertOutline
    },
  },
}
</script>

<style lang="scss">
.lead-history {
  &__entry {
    position: relative;

    &__status-chip {
      width: 34px;
      height: 34px;
      border: 2px solid white;
      filter: drop-shadow(0 0 2px #bbb);

      .v-icon__svg {
        width: 30px;
        height: 30px;
      }
    }

    &__chip {
      color: black;
      z-index: 1;
      width: 34px;
      height: 34px;
      border: 2px solid white;
      filter: drop-shadow(0 0 2px #bbb);

      .v-icon__svg {
        width: 20px;
        height: 20px;
      }
    }

    &__date {
      .v-chip {
        opacity: 0.6 !important;
      }
    }

    &:before {
      position: absolute;
      top: -0;
      bottom: calc(100% - 28px);
      left: 16px;
      border: 1px solid #eee;
      width: 1px;
      height: 100%;
      content: '';
    }
    &:after {
      position: absolute;
      top: 100%;
      left: 16px;
      border: 1px solid #eee;
      width: 1px;
      height: 100%;
      content: '';
    }

    &:first-child {
      &:before {
        content: inherit;
      }
      &:after {
        height: 100%;
      }
    }
    &:last-child {
      &:before {
        top: 0;
      }
      &:after {
        content: inherit;
      }
    }
  }
  .has-chip {
    &:first-child {
      &:after {
        top: 50%;
      }
    }
  }
}
</style>
